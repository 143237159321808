<template>
  <div class="home">
    <Header></Header>
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <!-- <el-container> -->
    <div class="main">
      <div class="carousel">
        <el-carousel height="100%" :interval="5000" arrow="always">
          <el-carousel-item>
            <img
              class="carousel-bg"
              src="../assets/home/carouselBg1.png"
              alt=""
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
              class="carousel-bg"
              src="../assets/home/carouselBg2.png"
              alt=""
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
              class="carousel-bg"
              src="../assets/home/carouselBg2.png"
              alt=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div id="firm-intro" class="firm-intro">
        <div class="firm-main">
          <div>
            <div class="firm-wrap">
              <h1>Company Profile</h1>
              <h1>公司简介</h1>
            </div>
            <p>
              内江汽车服务有限公司（以下简称公司）成立于2016年3月，注册资本3000万。由内江市国有资产经营管理有限责任公司和内江甜城交通服务有限公司合资组建，隶属于内江市国有资产监督管理委员会的国有企业。为市级机关企事业单位提供公务用车保障服务。
            </p>
            <p>
              公司资产规模4000余万元，自有车辆130余台，其中中型客车7台，越野车15台，商务车20台，轿车88台；5000余平米的大型室内停车场。
            </p>
          </div>
        </div>
        <div class="firm-bg">
          <img src="../assets/home/bg-i.png" alt="" />
        </div>
      </div>
      <div id="car-type" class="car-type">
        <div>
          <h1>MODEL BRAND</h1>
          <div class="car-brand">
            <h1>车型品牌</h1>
            <el-tabs
              :tab-position="tabPosition"
              @tab-click="getCar"
              value="小轿车"
            >
              <el-tab-pane
                v-for="(list, index) in carList"
                :key="index"
                :label="list.name"
                :name="list.name"
              >
                <div slot="label" style="display: block" @click="aa(index)">
                  <img
                    v-show="index != imgIndex"
                    class="imgNav"
                    :src="require(`../assets/home/${index}.png`)"
                    alt=""
                    srcset=""
                  /><img
                    v-show="index == imgIndex"
                    class="imgNav"
                    :src="require(`../assets/home/${index}_to.png`)"
                    alt=""
                    srcset=""
                  />
                  <span
                    style="
                      line-height: 26px;
                      display: inline-block;
                      vertical-align: top;
                      margin-left: 5px;
                    "
                    >{{ list.name }}</span
                  >
                </div>
                <div class="flex flex-wrap vehicle">
                  <div
                    class="vehicle-item"
                    v-for="(item, index) in dataList"
                    :key="index"
                  >
                    <div class="vehicle-show">
                      <img :src="host + item.vehicleImg" alt="" />
                      <button @click="toOnline(item.cid)">立即预约</button>
                    </div>
                    <div
                      class="
                        flex flex-row
                        justify-between
                        align-center
                        vehicle-bottom
                      "
                    >
                      <span class="v-title">{{ item.vehicleBrand }}</span>
                      <div v-if="item.described != null &&  item.described != ''" class="price">
                        <span> {{ item.described }}</span>
                      </div>
                      <div v-else class="price">
                        <span>¥ {{ item.vehicleFee }}</span> /天
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar">
      <div class="flex flex-column justify-between align-center sideba-item">
        <div
          class="
            flex flex-column
            justify-between
            align-center
            service-under service
          "
        >
          <img src="../assets/home/car.png" alt="" />
          <span>优惠租车</span>
        </div>
        <div
          class="
            flex flex-column
            justify-between
            align-center
            service-under service
          "
        >
          <img src="../assets/home/phone.png" alt="" />
          <span>电话咨询</span>
          <div class="home-phone">
            <span>电话：0832-2032666</span>
            <span>手机：18064942803</span>
          </div>
        </div>
        <div class="flex flex-column justify-between align-center service">
          <img src="../assets/home/code.png" alt="" />
          <span>二维码</span>
          <div class="home-code">
            <img src="../assets/home/xcx.png" alt="" />
          </div>
        </div>
      </div>

      <!-- <div>
            <i class="el-icon-arrow-up"></i>
          </div> -->
      <el-backtop target=".home" :bottom="100" :right="17">
        <div
          style="
             {
              height: 40px;
              width: 70px;
              background-color: #ffffff;
              box-shadow: 1px 5px 29px 0px rgba(0, 0, 0, 0.15);
              text-align: center;
              line-height: 40px;
              color: #9a9b9f;
              border-radius: 3px;
            }
          "
        >
          <i class="el-icon-arrow-up"></i>
        </div>
      </el-backtop>
    </div>
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="50%">
      <el-form
        :model="upPwd"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="旧 密 码" prop="oldPwd">
          <el-input
            v-model="upPwd.oldPwd"
            placeholder="请输入旧密码"
            show-password
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="新 密 码" prop="pwd">
          <el-input
            v-model="upPwd.pwd"
            placeholder="请输入新密码"
            show-password
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="isPwd">
          <el-input
            v-model="upPwd.isPwd"
            placeholder="请再次输入新密码"
            show-password
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div style="float: left;color: chocolate; padding-left: 20px;">
            密码过于简单，请及时更改密码！（密码需要长度大于6位，并且需要字母和数字组合）
          </div>
          <el-button type="primary" @click="setPwd('ruleForm')"
            >确 定</el-button
          >
        </el-form-item>
      </el-form>
      <!-- <span slot="footer" class="dialog-footer">
        <span style="float: left;color: chocolate; padding-left: 20px;">密码过于简单，请及时更改密码！（密码需要长度大于6位，并且需要字母和数字组合）</span>
        <el-button type="primary" @click="setPwd()">确 定</el-button>
      </span> -->
    </el-dialog>
    <Footer></Footer>
    <el-dialog
      title="内江汽车服务有限公司公告"
      :visible.sync="$store.state.announcement"
      width="40%"
      :before-close="handleClose"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <p style="text-align:left;text-indent:2em;">
        为感谢用车单位对公司的支持和信任，经公司办公会研究确定，自2024年7月1日起（报主管部门备案），对部分车辆租赁费进行下调。其他优惠政策同时享受。具体详见“服务报价表”或电话咨询0832-2032666。
      </p>
      <p style="text-align:left;text-indent:2em;">
        特此公告。
      </p>
      <p style="text-align:right;margin-top: 50px;">
        内江汽车服务有限公司
      </p>
      <p style="text-align:right;">
        2024年07月01日
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="announcementState"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import host from '@/config/host';
import { carLista, setPwd, isLogin } from '@/api/home';

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (!this.CheckPassWord(value)) {
        callback(new Error('密码需要长度大于6位，并且字母和数字组合!'));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.upPwd.pwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      host: host, //请求地址头
      tabPosition: 'left',
      dialogVisible: false,
      announcement: true,
      upPwd: {
        pwd: null,
        isPwd: null,
        oldPwd: null,
      },
      carList: [
        //车型列表
        {
          name: '小轿车',
          id: 1,
        },
        {
          name: '商务车',
          id: 2,
        },
        {
          name: '越野车',
          id: 3,
        },
        {
          name: '大巴车',
          id: 4,
          // },{
          //   name:"小巴车",
          //   vehicleTypeId:5
          // }]
        },
      ],
      dataList: [], //车
      navId: null,
      imgIndex: '',
      vehicleTypeId: 1,
      rules: {
        pwd: [{ validator: validatePass, trigger: 'blur' }],
        isPwd: [{ validator: validatePass2, trigger: 'blur' }],
        oldPwd: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
      },
    };
  },
  methods: {
    aa(index) {
      this.imgIndex = index;
      console.log(index);
    },
    announcementState() {
      this.$store.commit('setAnnouncement', false);
    },
    loadData() {
      debugger;
      if (this.$store.state.accessToken) {
        isLogin({ token: this.$store.state.accessToken }).then((res) => {
          if (res.code == 201) {
            this.$router.push({ path: '/Login' });
          }
        });
      } else {
        this.$router.push({ path: '/Login' });
      }

      var password = this.$store.state.password;
      if (!this.CheckPassWord(password)) {
        //  this.$message.info("密码过于简单，请及时更改密码");
        this.dialogVisible = true;
      }

      this.navId = this.$route.query.id;
      if (this.$route.query.id == 0) {
        document.getElementById('firm-intro').scrollIntoView();
      }
    },
    CheckPassWord(password) {
      //密码必须包含数字和字母
      var str = password;
      if (str == null || str.length < 6) {
        return false;
      }
      var reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
      if (reg.test(str)) {
        return true;
      } else {
        return false;
      }
    },
    setPwd() {
      this.$refs['ruleForm'].validate((valid) => {
        debugger;
        if (valid) {
          setPwd({
            pwd: this.upPwd.pwd,
            isPwd: this.upPwd.isPwd,
            oldPwd: this.upPwd.oldPwd,
          }).then((res) => {
            if (res.code == 1000) {
              this.$store.commit('setPassword', this.pwd);
              this.$message.success('更新密码成功');
              this.dialogVisible = false;
              this.$router.push({ path: '/Login' });
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getCar(t) {
      if (t.name == '小轿车') {
        this.vehicleTypeId = 1;
      } else if (t.name == '商务车') {
        this.vehicleTypeId = 2;
      } else if (t.name == '越野车') {
        this.vehicleTypeId = 3;
      } else {
        this.vehicleTypeId = 4;
      }
      carLista({ vehicleTypeId: this.vehicleTypeId }).then((res) => {
        if (res.code == 0) {
          this.dataList = res.data;
          if (this.navId == 1) {
            //跳转到品牌车型
            this.$nextTick(() => {
              document.getElementById('car-type').scrollIntoView();
            });
          }
        }
      });
    },
    toOnline(id) {
      console.log(id);
      this.$store.commit('setActiveIndex', 4);
      this.$router.push({
        name: 'OnlineTo',
        params: { brandId: id, vehicleTypeId: this.vehicleTypeId },
      });
    },
  },
  mounted() {
    this.loadData();
    this.$store.commit('setActiveIndex', 0);
    this.getCar({ name: '小轿车' });
  },
};
</script>
<style scoped>
/* element初始化 */
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.wrap {
  padding: 12px;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
/* .main >>> .el-carousel__arrow{
  width: 97px;
  height: 170px;
  border-radius: 2px;
  background-color:rgba(0, 0, 0, .5);
  font-size: 100px;
} */
/* 轮播 */
.carousel {
  height: calc(100vh - 84px);
}
.home >>> .el-carousel--horizontal {
  height: 100%;
}
.carousel-bg {
  width: 100%;
  height: 100%;
}
.home {
  height: 100vh;
  overflow-x: hidden;
}
/* 公司简历 */
.firm-main {
  /* padding: 0 350px; */
  margin-bottom: 74px;
}
.firm-main > div {
  width: 1200px;
  margin: 0 auto;
}
.firm-wrap {
  padding: 70px 0;
}
.firm-wrap h1:first-child {
  margin-bottom: 30px;
}
.firm-main h1,
.firm-main p {
  text-align: left;
  margin: 0;
}
.firm-main p {
  font-size: 16px;
  font-weight: 400;
  color: #595959;
  line-height: 25px;
}
.firm-bg {
  width: 100%;
  height: 745px;
  background: url('../assets/home/bg.png') no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.firm-bg img {
  width: 530px;
  height: 394px;
  position: absolute;
  top: 0;
  left: 80px;
  right: 0;
  bottom: -110px;
  margin: auto;
}
/*  */
.v-title {
  font-size: 16px;
  font-weight: 400;
  color: #656565;
  line-height: 25px;
}
.price {
  font-size: 14px;
  font-weight: 400;
  color: #b7b7b7;
  line-height: 25px;
}
.price span {
  color: #ff3c00;
}
.vehicle-item {
  width: 313px;
  margin: 0 0 20px 20px;
  border-radius: 8px;
  overflow: hidden;
}
.vehicle-item img {
  width: 100%;
  height: 212px;
}
.vehicle-show {
  position: relative;
}
.vehicle-item button {
  width: 73px;
  height: 30px;
  background-color: #3991fb;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 25px;
  border: none;
  cursor: pointer;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.vehicle-item:hover button {
  display: block;
}
.vehicle-bottom {
  padding: 0 20px;
  background-color: #ffffff;
  height: 50px;
}
.car-type {
  background-color: #f5f7fa;
}
.car-type > div {
  width: 1200px;
  padding: 107px 0 90px 0;
  margin: auto;
}
.car-type h1 {
  text-align: left;
}
/* 侧边栏 */
.sidebar >>> .el-backtop {
  width: 70px;
  height: 40px;
}
.sideba-item {
  width: 70px;
  height: 240px;
  position: fixed;
  right: 17px;
  bottom: 150px;
  z-index: 111111;
  background-color: #ffffff;
  box-shadow: 1px 5px 29px 0px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}
.service {
  position: relative;
  cursor: pointer;
}
.service img {
  width: 38px;
  height: 38px;
  margin-bottom: 8px;
}
.service span {
  font-size: 12px;
  color: #9a9b9f;
}
.service-under {
  position: relative;
}
.service-under::after {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: -8px;
  background-color: #9a9b9f;
}
.service .home-phone {
  position: absolute;
  left: -215px;
  top: 0;
  box-shadow: 1px 5px 29px 0px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
.home-phone {
  width: 200px;
  background-color: #ffffff;
  display: none;
}
.home-phone span {
  height: 30px;
  line-height: 30px;
  display: block;
  font-size: 18px;
}
.service:hover .home-phone {
  display: block;
}
.service .home-code {
  position: absolute;
  width: 186px;
  height: 198px;
  top: -15px;
  left: -206px;
  display: none;
}
.service:hover .home-code {
  display: block;
}
.home-code img {
  width: 100%;
  height: 100%;
}
.sideba-item {
  padding: 13px 11px;
  box-sizing: border-box;
}
/* 补充 */
.car-brand h1 {
  margin-bottom: 30px;
}
.imgNav {
  width: 40px;
  height: 26px;
}
</style>
